// ProductCard.js
import React from 'react';
import './ProductCard.css';

const ProductCard = ({ product, onBuyNow }) => {
  const { name, description, amount } = product;

  return (
    <div className="product-card">
      <h3 className="product-title">{name}</h3>
      <p className="product-description">{description}</p>
      <p className="product-price">₹{amount}</p>
      <button className="buy-now-button" onClick={() => onBuyNow(product)}>
        Buy Now
      </button>
    </div>
  );
};

export default ProductCard;
