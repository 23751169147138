// ProductList.js
import React from 'react';
import ProductCard from '../Components/ProductCard';
import './ProductList.css';

const ProductList = ({ products, onBuyNow }) => {
  return (
    <div className="product-list">
      {products.map((product) => (
        <ProductCard key={product.id} product={product} onBuyNow={onBuyNow} />
      ))}
    </div>
  );
};

export default ProductList;
