import React, { useState } from 'react';
import {
  MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCardImage, MDBRow, MDBCol, MDBInput, MDBRadio
} from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import UserService from './services/Register.js';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import './App.css';

function App(props) {
  
  const eventId = props.eventId;
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    gender: '',
    age: '',
    email: '',
    address: '',
    pincode: '',
    hasPet: null,
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!/^\d{10}$/.test(formData.phone)) newErrors.phone = 'Phone number must be exactly 10 digits';
    if (!formData.gender) newErrors.gender = 'Gender is required';
    if (!/^\d+$/.test(formData.age)) newErrors.age = 'Age must be a number';
    if (!/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Invalid email address';
    if (!formData.address.trim()) newErrors.address = 'Address is required';
    if (!/^\d{6}$/.test(formData.pincode)) newErrors.pincode = 'Pincode must be exactly 6 digits';
    if (formData.hasPet === null) newErrors.hasPet = 'Pet ownership status is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  const [authToken, setAuthToken] = useState(localStorage.getItem('accessToken'));

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleLogin = () =>{
    navigate("/login" , {replace : true})
  }

  const handlePhoneBlur = async () => {
    if (/^\d{10}$/.test(formData.phone)) { 
      try {
        const response = await UserService.verifyPhone("+91"+formData.phone);
        const { isNew } = response.data;
  
        if (!isNew) {
          toast.error('Phone number already exists. Please use a different phone number.');
          setErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone number already exists' }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, phone: '' }));
        }
      } catch (error) {
        toast.error('Error checking phone number: ' + error.message);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validate()) {
      toast.error('Validation failed. Please check your input fields.');
      return;
    }

    if (errors.phone === 'Phone number already exists') {
      toast.error('Phone number is already registered. Please use a different number.');
      return;
    }

    const phoneWithCountryCode = `+91${formData.phone}`;

    const dataToSubmit = {
      ...formData,
      phone: phoneWithCountryCode,
      hasPet: formData.hasPet === 'yes'
    };

    console.log('Data to submit:', dataToSubmit);

    try {
      const response = await UserService.registerUser(dataToSubmit, eventId);
      const result = response.data;
      const accessToken = result.access_token || result.acess_token;
      const refreshToken = result.refresh_token;

      // if (accessToken) {
      //   localStorage.setItem('accessToken', accessToken);
      // }
      // if (refreshToken) {
      //   localStorage.setItem('refreshToken', refreshToken);
      // }

      toast.success(result.message || 'Form submitted successfully!');
      console.log('Form submitted successfully:', result);

      // try {
      //   const otpResponse = await UserService.sendOtp(phoneWithCountryCode);
      //   console.log('OTP Response:', otpResponse.data);
      //   toast.success(otpResponse.data.message || 'OTP sent successfully!');
    
        navigate('/otp', {replace: true });

      // } catch (otpError) {
      //   console.error('Error sending OTP:', otpError.response?.data || otpError.message);
      //   toast.error('Failed to send OTP: ' + (otpError.response?.data.message || otpError.message));
      // }

    } catch (error) {
      if (error.response) {
        try {
          const errorResponse = error.response.data;
          const errorMessage = errorResponse.detail || 'Failed to submit the form: ' + error.message;
          toast.error(errorMessage);
        } catch (jsonError) {
          toast.error('Error submitting form: ' + error.message);
        }
      } else {
        toast.error('Error submitting form: ' + error.message);
      }
    }
  };

  const handleReset = () => {
    setFormData({
      name: '',
      phone: '',
      gender: '',
      age: '',
      email: '',
      address: '',
      pincode: '',
      hasPet: null
    });
    setErrors({});
  };

  return (
    <>
      <MDBContainer fluid className='bg-dark vh-100'>
        <MDBRow className='d-flex justify-content-center align-items-center custom-height'>
          <MDBCol>
            <MDBCard className='my-4'>
              <MDBRow className='g-0'>
                <div className="mobile-logo">
                  <img src="https://samply.in/wp-content/uploads/2024/11/download-1.png" alt="Logo" className="logo-img" />
                </div>
                <MDBCol md='6' className="d-none d-md-block">
                  <MDBCardImage src='https://i.pinimg.com/originals/d7/65/3a/d7653a2e53b4e9aa04e61b3b264be0e7.jpg' alt="Sample photo" className="rounded-start" fluid />
                </MDBCol>
                <MDBCol md='6'>
                  <MDBCardBody className='text-black d-flex flex-column justify-content-center'>
                    <h3 className="mb-5 text-uppercase fw-bold">Registration form</h3>
                    <MDBInput wrapperClass='mb-4' label='Name' size='lg' type='text' name='name' value={formData.name} onChange={handleChange} />
                    {errors.name && <p className="text-danger">{errors.name}</p>}
                    <MDBInput wrapperClass='mb-4' label='Phone' size='lg' type='text' name='phone' value={formData.phone} onChange={handleChange} onBlur={handlePhoneBlur} />
                    {errors.phone && <p className="text-danger">{errors.phone}</p>}
                    
                    <MDBInput wrapperClass='mb-4' label='Age' size='lg' type='text' name='age' value={formData.age} onChange={handleChange} />
                    {errors.age && <p className="text-danger">{errors.age}</p>}
                    <MDBInput wrapperClass='mb-4' label='Email' size='lg' type='email' name='email' value={formData.email} onChange={handleChange} />
                    {errors.email && <p className="text-danger">{errors.email}</p>}
                    <MDBInput wrapperClass='mb-4' label='Address' size='lg' type='text' name='address' value={formData.address} onChange={handleChange} />
                    {errors.address && <p className="text-danger">{errors.address}</p>}
                    <MDBInput wrapperClass='mb-4' label='Pincode' size='lg' type='text' name='pincode' value={formData.pincode} onChange={handleChange} />
                    {errors.pincode && <p className="text-danger">{errors.pincode}</p>}

                    <div className='mb-4'>
                      <h6 className="fw-bold mb-0">Gender:</h6>
                      <MDBRadio name='gender' id='inlineRadio1' value='Female' label='Female' inline checked={formData.gender === 'Female'} onChange={handleChange} />
                      <MDBRadio name='gender' id='inlineRadio2' value='Male' label='Male' inline checked={formData.gender === 'Male'} onChange={handleChange} />
                      <MDBRadio name='gender' id='inlineRadio3' value='Other' label='Other' inline checked={formData.gender === 'Other'} onChange={handleChange} />
                    </div>
                    {errors.gender && <p className="text-danger">{errors.gender}</p>}

                    <div className='d-md-flex justify-content-start align-items-center mb-4'>
                      <h6 className="fw-bold mb-0 me-4">Do you have a pet?</h6>
                      <MDBRadio name='hasPet' id='petYes' value={"yes"} label='Yes' inline checked={formData.hasPet === "yes"} onChange={handleChange} />
                      <MDBRadio name='hasPet' id='petNo' value={"no"} label='No' inline checked={formData.hasPet === "no"} onChange={handleChange} />
                    </div>
                    {errors.hasPet && <p className="text-danger">{errors.hasPet}</p>}

                    <div className='d-flex justify-content-end pt-3'>
                      <MDBBtn color='light' size='lg' onClick={handleReset}>Reset all</MDBBtn>
                      <MDBBtn className='ms-2' color='warning' size='lg' onClick={handleSubmit}>Submit form</MDBBtn>
                    </div>
                    
                    
                    <div className='d-flex justify-content-center align-items-center'>
                      <MDBBtn className='ms-2' color='warning' size='lg' style={{marginTop:10}} onClick={handleLogin}>
                        Already Registered ?
                      </MDBBtn>
                      </div>
                     
                    
                    
                  </MDBCardBody>
                </MDBCol>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <ToastContainer />
    </>
  );
}

export default App;
