import React, { useState } from "react";
import UserService from "../services/Register";
import logo from "../samply_logo.png"; // Import the logo

const CSVDownload = () => {
  const [selectedDate, setSelectedDate] = useState("");
  const [filterType, setFilterType] = useState("on"); // Default to "on"

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
  };

  const fetchData = async () => {
    if (!selectedDate) {
      alert("Please select a date.");
      return;
    }

    if(selectedDate){
      const url = `https://form.samply.in/api/users/download/data?date=${selectedDate}&filter=${filterType}`;
      window.open(url);
    }
  };

  return (
    <div style={styles.main}>
      <div style={styles.logoContainer}>
        <img src={logo} alt="Logo" style={styles.logo} />
      </div>
      <div style={styles.container}>
        <h4 style={styles.heading}>Select a Date</h4>
        <input
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          style={styles.dateInput}
        />
        <select
          value={filterType}
          onChange={handleFilterChange}
          style={styles.filterSelect}
        >
          <option value="on">On</option>
          <option value="before">Before</option>
          <option value="after">After</option>
        </select>
        <a href={`https://api.samply.in/api/users/download/data?date=${selectedDate}&filter=${filterType}`} style={styles.button}>
          Download Datas
        </a>
      </div>
    </div>
  );
};

const styles = {
  main: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f8f9fa",
  },
  logoContainer: {
    marginBottom: "20px",
  },
  logo: {
    maxWidth: "150px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#ffffff",
    padding: "30px",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    maxWidth: "400px",
    width: "100%",
  },
  heading: {
    marginBottom: "20px",
    fontSize: "1.5rem",
    color: "#343a40",
  },
  dateInput: {
    padding: "10px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ced4da",
    marginBottom: "10px",
    width: "100%",
    textAlign: "center",
  },
  filterSelect: {
    padding: "10px",
    fontSize: "1rem",
    borderRadius: "5px",
    border: "1px solid #ced4da",
    marginBottom: "20px",
    width: "100%",
  },
  button: {
    padding: "10px 20px",
    fontSize: "1rem",
    color: "#fff",
    backgroundColor: "#007bff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    width: "100%",
    textAlign: "center",
  },
};

export default CSVDownload;
