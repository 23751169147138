import React, { useEffect, useState } from 'react';
import ProductList from './ProductList';
import './ProductPageV2.css';
import UserService from '../services/Register';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const ProductPageV2 = () => {
  
  const [products,setProductsList] = useState([]);

  useEffect(()=>{
    const getProductsList = async() =>{ 
        try{
            const response = await UserService.getProductlist();
            setProductsList(response.data);
        }
        catch(error)
        {

        }
        
    }
    getProductsList();
  },[])

  const handleBuyNow = (product) => {
    handleOrder(product);
  };

  const handleOrder = async(product) =>{
    const data = {}
    data["amount"] = product.amount*100;
    data["product"] =product.id;
    const response = await UserService.getOrder(data);
    if(response.status == 200)
    {
        const res = response.data;
        console.log(res);
    }
    const options = {
        key: process.env.razorpay_key, 
        amount: product.amount, 
        currency: "INR", 
        order_id: response.data.id,
        name: "Samply",
        handler: function (response) {
            console.log("Payment Success", response);
            alert("Payment Sucessfull");
        },
        theme: {
            color: "#ffcc00",
        }
    };
    const razorpay = new window.Razorpay(options);
    razorpay.open();
}

  return (
    <div className="product-page">
      <header className="page-header">
        <h1>Welcome to Our Product Catalog</h1>
        <p>Discover quality products at unbeatable prices!</p>
      </header>
      <main className="page-main">
        <ProductList products={products} onBuyNow={handleBuyNow} />
      </main>
      <footer className="page-footer">
        <p>Samply &copy; 2024. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ProductPageV2;
