import React from 'react';
import "./AdminHeader.css";
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';


const AdminDashboard = () => {
  const navigate = useNavigate();

  const eventClick = () =>{
    navigate("/admin/events",{replace:true})
  }

  const settingsClick = () =>{
    navigate("/admin/commingsoon")
  }

  const downloadClick = () =>{
    navigate("/admin/data")
  }

  const handleLogout = () =>{
    
    Cookies.remove('accessToken', { domain: '.samply.in', path: '/' });
    navigate("/admin/",{replace:true});
  }

  const paymentClick = () =>{
    navigate("/admin/recent-payments")
  }



  return (
    <div className="dashboard-container">
      {/* Header Section */}
      <div className="dashboard-header">
        <h1 className="dashboard-title">Admin Dashboard</h1>
        <nav className="dashboard-nav">
          <button className="nav-button" onClick={eventClick}>Events</button>
          <button className="nav-button" onClick={downloadClick}>Download</button>
          <button className="nav-button" onClick={paymentClick}>Payments</button>


          {/* <button className="nav-button" onClick={settingsClick}>Settings</button> */}
          <button className="nav-button logout" onClick={handleLogout}>Logout</button>
        </nav>
      </div>
    </div>
  );
};


export default AdminDashboard;