import Axios from './axiosInstance';
import Cookies from 'js-cookie';
const UserService = {
  registerUser(data, eventId) {
    return Axios().post(`/users/register?eventId=${eventId}`, data);
},
  verifyPhone(phone) {
    return Axios().post('/users/verify-phone', { phone });
  },

  sendOtp(phone){ 
    const accessToken = Cookies.get('accessToken');
    console.log(accessToken);
    
    if (!accessToken) {
        throw new Error("Access token is missing. Please log in again.");
    }
    
    return Axios().post(
        `/users/sendOtp`,
        { phone },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    );
},

validateOtp(data) { 
  const accessToken = Cookies.get('accessToken');
  if (!accessToken) {
      throw new Error("Access token is missing. Please log in again.");
  }

  return Axios().post(
      `/users/validateOTP`,
      data, // data should include { otp, phone }
      {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
      }
  );
},
resendOtp(phonenum){
    const data = {
        "phone" : phonenum,
        "retryType" : "text"
    }
  const accessToken = Cookies.get('accessToken');
  if (!accessToken) {
      throw new Error("Access token is missing. Please log in again.");
  }

  return Axios().post(
      `/users/resendOtp`,
      data, 
      {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`,
          },
      }
  );
},
verificationCheck(){
    const accessToken = Cookies.get('accessToken');
    return Axios().post(
        `/users/checkVerification`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`,
            },
        }
    );
},
statusTrack(){
    const accessToken = Cookies.get('accessToken');
    console.log(accessToken);
    return Axios().get('users/statusTrack', 
        {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        }
    );
},
loginValidateOtp(data) {   
    return Axios().post(
        `users/login/validateOtp`,
        data, // data should include { otp, phone }
    );
  },

  loginsendOtp(phone){     
    return Axios().post(
        `/users/login/sendOtp`,
        { phone },
    );
},
adminLoginSendOtp(phone){
    return Axios().post(
        `/users/login/admin`,
        { phone },
    );
},
adminLoginValidateOtp(data){
    return Axios().post(
        `users/login/admin/validateOtp`,
        data, 
    );
},
borgesFeedback(data){
    return Axios().post(
        `users/feedBack/borges`,
        data, 
    );
},

downladoData(selectedDate,filterType){
    const apiUrl = `https://form.samply.in/api/users/download/data?date=${selectedDate}&filter=${filterType}`;
    const accessToken = Cookies.get('accessToken');
    return fetch(apiUrl, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`, // Replace with your actual auth token
          Accept: "text/csv",
        },
      });
},
getOrder(data){
    return Axios().post(`payment/create/order`,data);

},
getProductlist(){
    const accessToken = Cookies.get('accessToken');
    console.log(accessToken);
    return Axios().post(`product/list`,{
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
    });
},
checkEventType(){
    const accessToken = Cookies.get('accessToken');
    return Axios().post(`users/check/form/type`,{
        headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
    });
}


};

export default UserService;
