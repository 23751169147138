import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./EventListPage.css"; 
import AdminHeader from "../Header/header";

const EventListPage = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); 

  const fetchEvents = () => {
    setLoading(true);
    axios
      .get("https://api.samply.in/api/users/admin/eventList") 
      .then((response) => {
        setEvents(response.data);
        setLoading(false);
      })
      .catch((err) => {
        setError("Failed to fetch events.");
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchEvents(); 
  }, []);

  const handleEventClick = (eventId) => {
    navigate(`/admin/newUsers/${eventId}`);
  };

  return (
    <div className="admin-login-container">
      <AdminHeader />
    <div className="event-list-container">
      <h2>Event List</h2>
      
      {loading && <div>Loading events...</div>}
      {error && <div className="error-message">{error}</div>}
      {!loading && events.length === 0 && <div>No events found</div>}
      {!loading && events.length > 0 && (
        <div className="events-wrapper">
          {events.map((event) => (
            <div 
              key={event.event_id} 
              className="event-card"
              onClick={() => handleEventClick(event.event_id)}
            >
              <h3>{event.event_name}</h3>
              <p><strong>Event Date:</strong> {event.event_date}</p>
              <p><strong>Event Type:</strong> {event.event_type}</p>
              <p><strong>Address:</strong> {event.address}</p>
              <p>{event.is_expired ? "This event has expired" : "This event is active"}</p>
              <button className="event-button">Check Users</button>
            </div>
          ))}
        </div>
      )}
    </div>
    </div>
  );
};

export default EventListPage;
