import React, { useState } from "react";
import "./Question.css";

const Question = ({ question, options, type, onAnswer }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [otherValue, setOtherValue] = useState("");

  const handleChange = (option) => {
    setSelectedOption(option);
    if (option !== "Other") {
      onAnswer(option); // Notify parent with selected option
      setOtherValue(""); // Clear "Other" input if deselected
    }
  };

  const handleOtherChange = (e) => {
    const value = e.target.value;
    setOtherValue(value);
    onAnswer(value); // Notify parent with "Other" value
  };

  return (
    <div className="question-container">
      <h3>{question}</h3>
      <div className="options-container">
        {options.map((option, index) => (
          <label key={index} className="option-label">
            <input
              type={type === "checkbox" ? "checkbox" : "radio"}
              name={question}
              value={option}
              checked={selectedOption === option}
              onChange={() => handleChange(option)}
            />
            {option}
          </label>
        ))}
        {options.includes("Other") && (
          <>
            
            {selectedOption === "Other" && (
              <input
                type="text"
                placeholder="Please specify..."
                value={otherValue}
                onChange={handleOtherChange}
                className="other-input"
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Question;
